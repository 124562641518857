@font-face {
    font-family: 'AvenirNextLTW01-Regular';

    src: url('assets/fonts/AvenirNextLTW01-Regular.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextLTW01-Bold';

    src: url('assets/fonts/AvenirNextLTW01-Bold.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextLTW01-BoldCn';

    src: url('assets/fonts/AvenirNextLTW01-BoldCn.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-BoldCn.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextLTW01-DemiBold';

    src: url('assets/fonts/AvenirNextLTW01-Demi.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-Demi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextLTW01-DemiBoldCn';

    src: url('assets/fonts/AvenirNextLTW01-DemiCond.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-DemiCond.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextLTW01-Thin';

    src: url('assets/fonts/AvenirNextLTW01-Thin.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-Thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextLTW01-Light';

    src: url('assets/fonts/AvenirNextLTW01-Light.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextLTW01-UltraLight';

    src: url('assets/fonts/AvenirNextLTW01-UltraLight.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-UltraLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AvenirNextLTW01-Medium';

    src: url('assets/fonts/AvenirNextLTW01-Medium.woff2') format('woff2'),
    url('assets/fonts/AvenirNextLTW01-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    overflow-y: scroll;
}

.shine {
    background: #fff;

    background-image: linear-gradient(to right, #fff 0%, #bfc1c2 20%, #fff 40%, #fff 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;

    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    animation-fill-mode: forward;
    -webkit-animation-fill-mode: forward;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    -webkit-animation-name: placeholderShimmer;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
}

.shine:hover {
    background: #d5d5d5;
    background-color: #d5d5d5 !important;

    background-image: linear-gradient(to right, #d5d5d5 0%, #bfc1c2 20%, #d5d5d5 40%, #d5d5d5 100%);
    background-size: 800px 104px;
}


@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}